import React from 'react';
import { graphql, useStaticQuery, PageProps } from 'gatsby';
import { LayoutProvider } from '../../components/layout';
import { WarrantyCategoriesAccordions } from '../../components/sections';
import { StackOnBreadcrumbs } from '../../atoms';
import { stackStyled, Typography, useStackStyletron } from '../../theme';
import { useViewPortSize } from '../../hooks';
import { IFile } from '../../@types';
import { WarrantyInformationCard } from '../../atoms/Cards';
import { StackOnThemeT } from '../../theme/StacksOnTheme';

const query = graphql`
  query {
    strapiWarranty {
      category {
        title
        id
        subCategories {
          title
          id
          warrantyDoc {
            alternativeText
            caption
            created_at
            ext
            hash
            mime
            name
            provider
            size
            url
          }
        }
      }
      address
      email
      information
      title
    }
  }
`;

interface ISubCategories {
  id: number;
  title: string;
  warrantyDoc: IFile;
}

export interface ICategories {
  title: string;
  id: number;
  subCategories: Array<ISubCategories>;
}

export interface IWarranty {
  strapiWarranty: {
    title: string;
    information: string;
    email: string;
    address: string;
    category: Array<ICategories>;
  };
}

const Container = stackStyled('div', ({ isMobile }: { isMobile: boolean }) => ({
  maxWidth: '1280px',
  margin: '1em auto 3em auto',
  padding: isMobile ? 'auto' : '0 30px',
}));

const GridContainer = stackStyled('div', ({ isMobile }: { isMobile: boolean }) => ({
  display: 'grid',
  gridTemplateColumns: isMobile ? '1fr' : '30% auto',
  gridTemplateAreas: isMobile ? `'warrantyCategories' 'info'` : `'info warrantyCategories'`,
  columnGap: '40px',
  padding: '20px 0',
  width: '100%',
}));

const ContainerBreadCrumbs = stackStyled('div', (props: { isMobile: boolean }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: '26px',
}));

const InfoContainer = stackStyled('div', (props: { isMobile: boolean }) => ({
  gridArea: 'info',
  textAlign: 'center',
}));

const ColorGreen = stackStyled('span', (props: { $theme?: StackOnThemeT }) => ({
  color: props.$theme?.stackOnColors?.primaryGreenDark,
}));

const IndexPage: React.FC<PageProps> = (props: PageProps) => {
  const data = useStaticQuery<IWarranty>(query);

  const [isMobile, isTablet] = useViewPortSize();
  const [css, theme] = useStackStyletron();

  return (
    <LayoutProvider seo={false} locationSearch={props.location.search}>
      <Container isMobile={false}>
        <ContainerBreadCrumbs isMobile={isMobile || isTablet}>
          <StackOnBreadcrumbs
            pages={[
              { title: 'Home', href: '/' },
              { title: 'Customer service', href: '/customer-service' },
              { title: `Warranty`, href: `/customer-service/warranty-info` },
            ]}
          />
        </ContainerBreadCrumbs>
        <Typography
          $fontFamily={'Montserrat'}
          $weight={700}
          $tagType={isMobile ? 'h3Mob' : 'h3'}
          $textAlign='left'
          $margin='0 0 32px 0'
        >
          <ColorGreen>{data.strapiWarranty.title}</ColorGreen>
        </Typography>
        <GridContainer isMobile={isMobile || isTablet}>
          <InfoContainer isMobile={isMobile || isTablet}>
            <WarrantyInformationCard
              title={data.strapiWarranty.title}
              information={data.strapiWarranty.information}
              email={data.strapiWarranty.email}
              address={data.strapiWarranty.address}
            />
          </InfoContainer>
          <WarrantyCategoriesAccordions categories={data.strapiWarranty.category} />
        </GridContainer>
      </Container>
    </LayoutProvider>
  );
};

export default IndexPage;
